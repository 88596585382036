<template>
    <div>
        <div class="header">
            <ds-header style="width: 100%"></ds-header>
            <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
        </div>
        <a-form class="formModel" layout="horizontal" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-form-item label="企业名称" required>
                <a-select style="width: 800px;" :disabled="!!$route.query.id"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode" show-search v-model="add.enterpriseId"
                    placeholder="输入企业名称以选择" :default-active-first-option="false" :show-arrow="false"
                    :filter-option="false" :not-found-content="null" @search="getEnterpriseProjectList"
                    @select="selectEnterPrise">
                    <a-select-option v-for="item in enterpriseMsg" :key="item.enterpriseId">
                        {{ item.enterpriseName }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="所属项目" required>
                <a-select style="width: 800px;" :disabled="!!$route.query.id"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                    :maxTagTextLength="5" placeholder="请选择所属项目" v-model="add.projectIdList">
                    <a-select-option v-for="item in EprojectList" :key="item.projectId">
                        {{ item.projectName }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-model-item style="z-index: 0;" label="产品介绍">
                <div class="content" style="width: 800px;">
                    <div id="editer"></div>
                </div>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 6, offset: 8 }">
                <a-button type="primary" @click="handleAddOk" style="margin-right: 20px"> 确定 </a-button>
                <a-button type="info" @click="goBack"> 取消 </a-button>
            </a-form-model-item>
        </a-form>
    </div>
</template>
<script>
import * as api from "@/api/enterpriseDisplayManagement/index.js";
import wangEditor from "wangeditor";
import { IMG_API } from "@/config";
export default {
    data() {
        return {
            add: {
                enterpriseId: undefined,
                projectIdList: [],
                productPresentation: ''
            },
            enterpriseMsg: [],// 新增企业
            EprojectList: [],// 新增企业
            IMG_API: IMG_API,
            info: {}
        }
    },
    async mounted() {
        await this.createEditor()
    },
    methods: {
        handleAddOk(params) {
            console.log(this.$route.query.id, '================');
            if (!this.$route.query.id) {
                api.addShowEnterprise(this.add).then((res) => {
                    console.log(res, '==============>');
                    if (res.code === "200") {
                        this.addShow = false;
                        this.$message.success("添加成功");
                        this.goBack()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                api.updateShowEnterprise({ ...this.add, id: this.$route.query.id }).then((res) => {
                    console.log(res, '==============>');
                    if (res.code === "200") {
                        this.addShow = false;
                        this.$message.success("添加成功");
                        this.goBack()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 取消
        goBack() {
            this.$router.go(-1);
        },
        async getEnterpriseProjectList(val) {
            let type = this.$route.query.id ? 2 : 1
            let params = {
                "enterpriseName": val,
                "page": 0,
                "size": 100,
                type
            }
            let res = await api.getEnterpriseProjectList(params)
            this.enterpriseMsg = res.data.enterpriseList
            console.log(res, '==================>');
        },
        //选择企业
        selectEnterPrise(value, type) {
            this.getProject(value);
            if (!this.$route.query.id) {
                this.add.projectIdList = []
            }

        },
        // 获取所属项目
        getProject(id) {
            this.enterpriseMsg.forEach((item) => {
                if (item.enterpriseId === id) {
                    this.EprojectList = item.projectList;
                }
            });
        },

        // 创建富文本
        async createEditor() {
            const _this = this;
            const editor = new wangEditor(`#editer`);
            // 配置 onchange 回调函数，将数据同步到 vue 中
            editor.config.onchange = (newHtml) => {
                this.add.productPresentation = newHtml;
            };
            // 隐藏全屏
            editor.config.showFullScreen = false
            editor.config.menus = [
                "fontSize",
                "foreColor",
                "backColor",
                "underline",
                "italic",
                "bold",
                "justify",
                "splitLine",
                "undo",
                "redo",
                "list",
                "table",
                "image",
                // "video",
            ];
            editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
            editor.config.uploadImgServer = this.IMG_API + "/oss/files";
            editor.config.uploadImgShowBase64 = false;
            editor.config.showLinkImg = false;
            editor.config.showLinkVideo = false;
            editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
            editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
            editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
            editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
            // editor.config.uploadVideoAccept = ['mp4']
            editor.config.uploadFileName = "file";
            editor.config.uploadVideoName = "file";
            editor.config.uploadVideoTimeout = 60000;
            editor.config.uploadImgTimeout = 60000;
            editor.config.uploadVideoHooks = {
                // 上传视频之前
                before: function (xhr, editor, files) {
                    console.log(xhr);
                    console.log(editor);
                    console.log(files);
                },
                // 视频上传并返回了结果，视频插入已成功
                success: function (xhr) {
                    // this.$message.success('上传视频成功')
                },
                // 视频上传并返回了结果，但视频插入时出错了
                fail: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频出错，一般为 http 请求的错误
                error: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频超时
                timeout: function (xhr) {
                    _this.$message.error("上传请求超时");
                },
                // 视频上传并返回了结果，想要自己把视频插入到编辑器中
                // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
                customInsert: function (insertVideoFn, result) {
                    let videoHTML =
                        '&nbsp;<video poster="' +
                        result.redirect_uri +
                        '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
                        result.redirect_uri +
                        '" controls style="max-width:100%"></video>&nbsp;';
                    editor.cmd.do("insertHTML", videoHTML);
                    // result 即服务端返回的接口
                    // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                    // insertVideoFn(result.redirect_uri)
                },
            };
            editor.config.uploadImgHooks = {
                // 上传视频之前
                before: function (xhr) { },
                // 视频上传并返回了结果，视频插入已成功
                success: function (xhr) {
                    // this.$message.success('上传视频成功')
                },
                // 视频上传并返回了结果，但视频插入时出错了
                fail: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频出错，一般为 http 请求的错误
                error: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频超时
                timeout: function (xhr) {
                    _this.$message.error("上传请求超时");
                },
                // 视频上传并返回了结果，想要自己把视频插入到编辑器中
                // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
                customInsert: function (insertVideoFn, result) {
                    // result 即服务端返回的接口
                    // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                    insertVideoFn(result.redirect_uri);
                },
            };
            editor.config.customAlert = function (s, t) {
                switch (t) {
                    case "success":
                        _this.$message.success(s);
                        break;
                    case "info":
                        _this.$message.info(s);
                        break;
                    case "warning":
                        _this.$message.warning(s);
                        break;
                    case "error":
                        _this.$message.error(s);
                        break;
                    default:
                        _this.$message.info(s);
                        break;
                }
            };
            editor.config.placeholder = "请输入";
            // 创建编辑器
            editor.create();
            this.editor = editor;
            // 查看的时候禁用富文本
            if (this.$route.query.type === "info") {
                this.editor.disable();
            } else {
                this.editor.enable();
            }
        },
    },
    async created() {
        let res = await api.detailShowEnterprise(this.$route.query.id)
        this.editor.txt.html(res.data.productPresentation);
        this.add.productPresentation = res.data.productPresentation
        this.add.enterpriseId = res.data.enterpriseId,
            this.add.projectIdList = res.data.projectIdList
        await this.getEnterpriseProjectList(res.data.enterpriseName)
        await this.selectEnterPrise(res.data.enterpriseId)

    }
}

</script>
<style lang='scss' scoped>
.header {
    display: flex;
    justify-content: space-between;
}
</style>