import http, { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
// 列表
export const getEnterpriseList = (data) => {
    return fetchApi(URL.GET_ETERPRISELIST, data, "post", 'data',true);
  };
  
// 顺义获取项目接口
export function getShunyiAllProject(data) {
    return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data',true);
  }
// 顺义获取公司项目接口
export function getEnterpriseProjectList(data) {
    return fetchApi(URL.GET_ETERPRISEPROJECTLIST, data, 'post', 'data',true);
  }
// 顺义添加公司项目接口
export function addShowEnterprise(data) {
    return fetchApi(URL.ADDSHOWENTERPRISE, data, 'post', 'data');
  }
export function updateShowEnterprise(data) {
    return fetchApi(URL.UPDATESHOWENTERPRISE, data, 'put', 'data');
  }
export function detailShowEnterprise(data) {
    return fetchApi(URL.DETAILSHOWENTERPRISE+data, 'get');
  }
  // 删除
export function delShowEnterprise(data) {
    return fetchApi(URL.DELLSHOWENTERPRISE+ data, {},'DELETE', 'data');
  }
